import { ReactNode, forwardRef } from 'react';

import { Button as AriaButton } from 'react-aria-components';

import styles from './Button.module.css';

type Variant = 'Primary' | 'Secondary' | 'Tertiary';

type ButtonSize = 'Compact' | 'Default';

type ButtonProps = {
  variant: Variant;
  size: ButtonSize;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel: string;
  interactiveIcon?: boolean;
  isDestructive?: boolean;
};

const Button = forwardRef(
  (
    {
      variant = 'Primary',
      size = 'Default',
      leftIcon,
      rightIcon,
      className,
      disabled,
      onClick,
      children,
      loading,
      type = 'button',
      ariaLabel,
      interactiveIcon = false,
      isDestructive = false,
    }: ButtonProps,
    forwardedRef: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <AriaButton
        isDisabled={disabled || loading}
        onPress={onClick}
        // eslint-disable-next-line max-len
        className={`${styles.Button} ${styles[`Button${variant}`]} ${styles[`ButtonSize${size}`]} ${interactiveIcon ? `${styles.InteractiveIcon}` : ''} ${isDestructive ? `${styles.Destructive}` : ''} ${className}`}
        type={type}
        aria-label={ariaLabel}
        ref={forwardedRef}
      >
        {loading && (
          <div className={styles.ButtonLoading}>
            <span className={styles.Cir1} />
            <span className={styles.Cir2} />
            <span className={styles.Cir3} />
          </div>
        )}
        <div className={`${styles.container} ${loading ? styles.hidden : ''}`}>
          {leftIcon && Object.keys(leftIcon).length > 0 && (
            <span className={`${styles.LeftIconContainer}`}>{leftIcon}</span>
          )}
          {children}
          {rightIcon && Object.keys(rightIcon).length > 0 && (
            <span className={`${styles.RightIconContainer}`}>{rightIcon}</span>
          )}
        </div>
      </AriaButton>
    );
  }
);

export default Button;
